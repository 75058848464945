import { useEffect, useMemo, useState } from 'react'
import { MEDIA_BREAKPOINTS } from 'src/utils/constants'

export const isBrowser = typeof window !== 'undefined'

/**
 * Fix infinite loop from https://github.com/streamich/react-use/blob/master/src/factory/createBreakpoint.ts
 */
const createBreakpoint =
  (
    breakpoints: { [name: string]: number } = {
      laptopL: 1440,
      laptop: 1024,
      tablet: 768,
    },
  ) =>
  () => {
    const [screen, setScreen] = useState(isBrowser ? window.innerWidth : 0)

    useEffect(() => {
      const setSideScreen = (): void => {
        setScreen(window.innerWidth)
      }
      setSideScreen()
      window.addEventListener('resize', setSideScreen)
      return () => {
        window.removeEventListener('resize', setSideScreen)
      }
    }, [])
    const sortedBreakpoints = useMemo(
      () => Object.entries(breakpoints).sort((a, b) => (a[1] >= b[1] ? 1 : -1)),
      [breakpoints],
    )
    const result = sortedBreakpoints.reduce((acc, [name, width]) => {
      if (screen >= width) {
        return name
      } else {
        return acc
      }
    }, sortedBreakpoints[0][0])
    return result
  }

export const useBreakpoint = createBreakpoint(MEDIA_BREAKPOINTS)
