import { useEffect, useState } from 'react'

enum PlatformStatus {
  Online = 'online',
  Maintenance = 'maintenance',
  Degraded = 'degraded',
  Downtime = 'downtime',
}

export const usePlatformStatus = (): PlatformStatus => {
  const [platformStatus, setPlatformStatus] = useState<PlatformStatus>(
    PlatformStatus.Online,
  )

  const retrieveLatestStatus = async () => {
    try {
      const response = await fetch('https://api.upvio.com/_/status')
      const { status, maintenanceActive } = await response.json()
      if (maintenanceActive) {
        setPlatformStatus(PlatformStatus.Maintenance)
      } else {
        setPlatformStatus(
          status.replace('operational', 'online') as PlatformStatus,
        )
      }
    } catch (error) {
      console.debug('Error fetching platform status.', error)
    }
  }

  useEffect(() => {
    retrieveLatestStatus()
    const timer = setInterval(retrieveLatestStatus, 5 * 60 * 1000)

    return () => clearInterval(timer)
  }, [])

  return platformStatus
}
