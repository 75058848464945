import { DataEnrichment, Lightning, Phone, Warning } from '@carbon/icons-react'
import { Button, HeaderBanner, useDisclosure } from 'australis'
import { useAvailableFeatures } from 'src/hooks/useAvailableFeatures'
import { useBusinessRouter } from 'src/hooks/useBusinessRouter'
import { useCurrentBusiness } from 'src/hooks/useCurrentBusiness'
import { useCurrentStaff } from 'src/hooks/useCurrentStaff'
import { useSubscription, SubscriptionStatus } from 'src/hooks/useSubscription'

const SETUP_TEXT = 'We will help you setup your account.'
const TRIAL_TEXT = 'You have %DAYS% days left on your trial.'
const PAST_DUE_TEXT =
  'Your account is past due. Please pay your outstanding balance.'

const TrialBanner: React.FC = () => {
  const { isOpen, toggle } = useDisclosure(true)
  const availableFeatures = useAvailableFeatures()
  const businessRouter = useBusinessRouter()
  const { currentBusiness } = useCurrentBusiness()
  const currentStaff = useCurrentStaff()
  const { subscriptionStatus, remainingTrialDays, requiresPayment } =
    useSubscription()
  const stripeData = currentBusiness?.stripeData

  const isAdmin = !!currentStaff?.isBusinessAdmin

  // Only enable this when billing is enabled for this business
  if (!availableFeatures['billing']) {
    return null
  }

  // Only admins can action these banners, so only they should see them
  if (!isAdmin) {
    return null
  }

  // When subscriptions are cancelled or never existed they have no status
  if (subscriptionStatus === null) {
    return (
      <HeaderBanner
        show={isOpen}
        variant='error'
        onClose={() => toggle()}
        icon={<Warning size={18} />}
        action={
          isAdmin ? (
            <Button
              size='sm'
              variant='solid'
              color='warning'
              onPress={() => businessRouter.push('/settings/billing/manage')}
            >
              <Lightning />
              Purchase
            </Button>
          ) : null
        }
      >
        You do not have an active subscription. Please purchase a subscription
        to continue using your account.
      </HeaderBanner>
    )
  }

  // Paat due accounts have permanent banner
  if (requiresPayment) {
    const cta = stripeData?.subscription?.latestInvoiceUrl
      ? () => window.open(stripeData.subscription.latestInvoiceUrl, '_blank')
      : () => businessRouter.push('/settings/billing/manage')
    return (
      <HeaderBanner
        show={isOpen}
        variant='error'
        onClose={() => toggle()}
        icon={<Warning size={18} />}
        isDismissable={false}
        action={
          isAdmin ? (
            <Button size='sm' variant='solid' color='warning' onPress={cta}>
              <Lightning />
              Pay Now
            </Button>
          ) : null
        }
      >
        {PAST_DUE_TEXT}
      </HeaderBanner>
    )
  }

  // Trial banners should only show when the subscription is in trialing state
  if (subscriptionStatus !== SubscriptionStatus.Trialing) {
    return null
  }

  // If there is no value for trial days then we have no useful message to show next
  if (remainingTrialDays === null) {
    return null
  }

  // First stage of the trial offers setup help
  if (remainingTrialDays >= 8) {
    return (
      <HeaderBanner
        show={isOpen}
        variant='info'
        icon={<DataEnrichment size={18} />}
        onClose={() => toggle()}
        action={
          isAdmin ? (
            <Button
              size='sm'
              variant='solid'
              color='primary'
              elementType='a'
              target='_blank'
              href='https://upvio.com/lp/setup'
            >
              <Phone />
              Book a Call Now
            </Button>
          ) : null
        }
      >
        {SETUP_TEXT}
      </HeaderBanner>
    )
  }

  // Final stage of the trial pushes to upgrade plan
  if (remainingTrialDays <= 7) {
    return (
      <HeaderBanner
        show={isOpen}
        variant='warning'
        onClose={() => toggle()}
        icon={<Warning size={18} />}
        action={
          isAdmin ? (
            <Button
              size='sm'
              variant='solid'
              color='warning'
              onPress={() => businessRouter.push('/settings/billing/manage')}
            >
              <Lightning />
              Upgrade Now
            </Button>
          ) : null
        }
      >
        {TRIAL_TEXT.replace('%DAYS%', remainingTrialDays.toString())}
      </HeaderBanner>
    )
  }

  return null
}

export default TrialBanner
