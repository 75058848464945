import { useCurrentBusiness } from 'src/hooks/useCurrentBusiness'
import { SubscriptionStatus } from 'src/types/graphql'

export { SubscriptionStatus }

interface Subscription {
  subscriptionStatus: SubscriptionStatus | null

  /**
   * The number of days remaining on a trial
   * If the value is null, there is no active trial
   */
  remainingTrialDays: number | null

  /**
   * True if there is a recent invoice that is not paid.
   * Subscriptions in this state cannot invite staff or modify their seats.
   */
  requiresPayment: boolean
}

/**
 * Get various data about the current business subscription.
 */
export const useSubscription = (): Subscription => {
  const { currentBusiness } = useCurrentBusiness()

  const subscriptionStatus = currentBusiness?.subscriptionStatus || null
  const remainingTrialDays = currentBusiness?.remainingTrialDays ?? null
  const requiresPayment =
    subscriptionStatus === SubscriptionStatus.Incomplete ||
    subscriptionStatus === SubscriptionStatus.PastDue

  return {
    subscriptionStatus,
    remainingTrialDays,
    requiresPayment,
  }
}
