import Link from 'next/link'
import React from 'react'
import { useBusinessRouter } from 'src/hooks/useBusinessRouter'
import { useCurrentBusiness } from 'src/hooks/useCurrentBusiness'
import { useCurrentUser } from 'src/hooks/useCurrentUser'

type BusinessNameProps = {
  variant?: 'light' | 'dark'
}

const BusinessName: React.FC<BusinessNameProps> = ({ variant = 'dark' }) => {
  const currentUser = useCurrentUser()
  const { currentBusiness } = useCurrentBusiness()
  const businessRouter = useBusinessRouter()

  if ((currentUser?.businessesCount || 0) === 1) {
    return null
  }

  return (
    <Link
      href={businessRouter.getHref('settings/profile')}
      style={{
        textDecoration: 'none',
        color: variant === 'light' ? 'white' : 'black',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {currentBusiness?.name || ''}
    </Link>
  )
}

export default BusinessName
