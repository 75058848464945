import {
  CurrentStaffQuery,
  StaffRole,
  useCurrentStaffQuery as useCurrentStaffApolloQuery,
} from 'src/types/graphql'
import { useCurrentBusinessId } from './useCurrentBusiness'

type UserStaff = NonNullable<CurrentStaffQuery['currentStaff']>

interface CurrentStaff extends UserStaff {
  /**
   * Avoid using this for permission checks for most things.
   * Admin is required to manage the business, and owner check is usually
   * just for display.
   */
  isBusinessOwner: boolean

  /**
   * The staff has admin privileges over the business.
   * Usually this means they can access settings and view all data.
   * Owners implicitly have this role.
   */
  isBusinessAdmin: boolean
}

/**
 * Staff member for the currently logged in user within the currently selected business.
 */
export const useCurrentStaff = (): CurrentStaff | null => {
  const { currentStaff } = useCurrentStaffQuery()

  return currentStaff || null
}

export const useCurrentStaffQuery = () => {
  const currentBusinessId = useCurrentBusinessId()

  const { data, loading, error, refetch } = useCurrentStaffApolloQuery({
    variables: {
      businessId: currentBusinessId || '',
    },
    fetchPolicy: 'cache-first',
    pollInterval: 5 * 60 * 1000,
    skip: !currentBusinessId,
  })

  const roles = data?.currentStaff?.roles || []
  const isBusinessOwner = roles.includes(StaffRole.Owner)
  const isBusinessAdmin = roles.includes(StaffRole.Admin)

  const currentStaff = data?.currentStaff
    ? {
        ...data?.currentStaff,
        isBusinessOwner,
        isBusinessAdmin,
      }
    : null

  return {
    currentStaff,
    loading,
    error,
    refetch,
  }
}
