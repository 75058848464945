import Head from 'next/head'
import { FC, useEffect } from 'react'
import { create } from 'zustand'

interface Props {
  children: string | string[]
}

interface PageTitleStore {
  title: string
  setTitle: (title: string) => void
}

const usePageTitleStore = create<PageTitleStore>((set) => ({
  title: 'Upvio Account Portal',
  setTitle: (title: string) => set({ title }),
}))

/**
 * Get the current page title set by <PageTitle> component.
 */
export const usePageTitle = () => usePageTitleStore((state) => state.title)

/**
 * Populates the page <title> tag and makes the value accessible via a hook.
 */
const PageTitle: FC<Props> = ({ children }) => {
  const title = Array.isArray(children) ? children.join('') : children
  const { setTitle } = usePageTitleStore()

  useEffect(() => {
    setTitle(title)
  }, [title, setTitle])

  return (
    <Head>
      <title>{title} | Upvio</title>
    </Head>
  )
}

export default PageTitle
