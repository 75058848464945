import { useCurrentStaffQuery } from 'src/hooks/useCurrentStaff'

import type { FRONTEND_FEATURE_KEYS } from 'src/features'

export type Features = Record<FRONTEND_FEATURE_KEYS[number], boolean> & {
  /** If true, the query is still in flight and results have not yet been returned */
  loading: boolean
}

/**
 * Get all available features for the current context.
 * Combines features from currentUser and currentBusiness.
 */
export const useAvailableFeatures = (): Features => {
  const { loading, currentStaff } = useCurrentStaffQuery()
  const staffFeatures = currentStaff?.availableBoolFeatures || []

  const features = staffFeatures.reduce(
    (features, feature) => {
      features[feature] = true
      return features
    },
    {} as Record<string, boolean>,
  )
  return {
    ...features,
    loading,
  } as Features
}
