import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { useCurrentBusinessRouteAlias } from './useCurrentBusiness'

export const useBusinessRouter = () => {
  const router = useRouter()
  const currentBusinessAlias = useCurrentBusinessRouteAlias()
  const basePath = `/businesses/${currentBusinessAlias}`
  const baseUrl = `https://${window.location.hostname}${basePath}`

  /**
   * The path without the base /businesses/[alias] prefix
   * Useful for checking the current page
   */
  const asRelativePath = router.asPath.replace(basePath, '')

  const push = useCallback(
    (path: string) => {
      router.push(`${basePath}${path}`)
    },
    [basePath, router],
  )

  const getHref = useCallback(
    (path: string, absolute: boolean = false) => {
      if (path.indexOf('https://') === 0) {
        return path
      }
      if (path.indexOf('/admin') === 0) {
        return path
      }
      path = path.startsWith('/') ? path : `/${path}`
      return `${absolute ? baseUrl : basePath}${path}`
    },
    [basePath, baseUrl],
  )

  const getBusinessFormUrl = (path = '') => {
    const hostname = window.location.hostname.replace('account.', 'forms.')
    const formsBaseUrl = `https://${hostname}/book/${currentBusinessAlias}`

    return `${formsBaseUrl}/${path}`
  }

  const removeQueryParam = (key: string) => {
    const { pathname, query } = router
    delete query[key]
    router.replace({ pathname, query }, undefined, {
      shallow: true,
    })
  }

  const getPatientPortalUrl = (path = '') => {
    const hostname = window.location.hostname.replace('account.', 'patients.')
    const patientPortalBaseUrl = `https://${hostname}/${currentBusinessAlias}`

    return `${patientPortalBaseUrl}/${path}`
  }

  return {
    push,
    basePath,
    baseUrl,
    getHref,
    getBusinessFormUrl,
    getPatientPortalUrl,
    asPath: router.asPath,
    asRelativePath,
    query: router.query,
    removeQueryParam,
  }
}
